import LayoutContainer from '@/components/LayoutContainer';

import authImg from '@/assets/web/auth.webp';

import styles from './styles.module.less';

const isWideScreen = window.innerWidth > 800;

export const AuthContainer: React.FC<
  React.PropsWithChildren<{
    desc: React.ReactNode;
    // eslint-disable-next-line arrow-body-style
  }>
> = ({ children, desc }) => (
  <LayoutContainer>
    <div className={isWideScreen ? styles.horizontalLayout : styles.verticalLayout}>
      <img className={styles.image} src={authImg} alt='auth' />
      <div className={styles.auth}>
        {children}
        <div className={styles.divider} dir='auto' />
        <div className={styles.desc} dir='auto'>
          {desc}
        </div>
      </div>
    </div>
  </LayoutContainer>
);
