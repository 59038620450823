import firebase from 'firebase/compat/app';

import { CodekidzAuth } from './codekidz-auth';
import { FirebaseAuth } from './firebase-auth';
import { SupabaseAuth } from './supabase-auth';
import { eventBus } from '../event-bus';
import { authDomain, authPlatform } from './config';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyB4WuTjw95BFb6WcV4FGnhmLSFnOBhGOMo',
  authDomain,
  projectId: 'codekidz',
  storageBucket: 'codekidz.appspot.com',
  messagingSenderId: '197085792628',
  appId: '1:197085792628:web:5d56be15612cf37b2a8421',
  measurementId: 'G-5HSBBTDP26',
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// 子账号登录
const codekidzAuth = new CodekidzAuth();
// 三方登录

// eslint-disable-next-line import/no-mutable-exports, no-nested-ternary
let auth = CodekidzAuth.isAvailable()
  ? codekidzAuth
  : authPlatform === 'supabase'
  ? new SupabaseAuth()
  : new FirebaseAuth();

eventBus.on('USE_LOGIN_TYPE', (type) => {
  auth.dispose();
  if (type === 'codekidz') {
    auth = codekidzAuth;
  } else {
    // 强制重新初始化
    auth = authPlatform === 'supabase' ? new SupabaseAuth() : new FirebaseAuth();
  }
});

export { authPlatform, auth };
