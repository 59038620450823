import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import type { IAuthUserInfo } from './service/interface';

import { AuthGoogle } from './pages/Auth/AuthGoogle';
import { AuthInternal } from './pages/Auth/AuthInternal';
import { AuthSupabase } from './pages/Auth/AuthSupabase';
import { auth, authPlatform } from './service/auth';
import { eventBus } from './service/event-bus';
import { useGlobalUserState } from './states/global-user';
import { loadWithSuspense } from './utils/suspense-wrapper';
import { isMobile } from './utils/is-mobile';

import './App.css';

const BookPreview = loadWithSuspense(lazy(() => import('./pages/BookPreview')));
const MobileBookPreview = loadWithSuspense(lazy(() => import('./pages/BookPreview/mobile-view')));
const ResetPassword = loadWithSuspense(lazy(() => import('./pages/Auth/ResetPassword')));
const SideBar = loadWithSuspense(lazy(() => import('./pages/SideBar')));
const OrderSuccess = loadWithSuspense(lazy(() => import('./pages/Pricing/OrderSuccess')));
const OrderCanceled = loadWithSuspense(lazy(() => import('./pages/Pricing/OrderCanceled')));
const PlayGround = loadWithSuspense(lazy(() => import('./pages/PlayGround')));
const Course = loadWithSuspense(lazy(() => import('./pages/Course')));
const Home = loadWithSuspense(lazy(() => import('./pages/Home/Home')));
const LearnAI = loadWithSuspense(lazy(() => import('./pages/LearnAI')));
const LearnPython = loadWithSuspense(lazy(() => import('./pages/LearnPython')));
const Privacy = loadWithSuspense(lazy(() => import('./pages/Privacy')));
const PaymentPolicy = loadWithSuspense(lazy(() => import('./pages/PaymentPolicy')));
const Partnership = loadWithSuspense(lazy(() => import('./pages/Partnership')));
const TermsOfUse = loadWithSuspense(lazy(() => import('./pages/TermsOfUse')));
const MobileHome = loadWithSuspense(lazy(() => import('./pages/MobileHome')));
const Teacher = loadWithSuspense(lazy(() => import('./pages/Teacher')));
const ChooseCourse = loadWithSuspense(lazy(() => import('./pages/ChooseCourse')));
const PageNotFound = loadWithSuspense(lazy(() => import('./pages/PageNotFound')));
const Game = loadWithSuspense(lazy(() => import('./pages/Game')));
const Trophy = loadWithSuspense(lazy(() => import('./pages/Trophy')));
const LessonIntro = loadWithSuspense(lazy(() => import('./pages/LessonIntro')));
const StudentManagement = loadWithSuspense(lazy(() => import('./pages/StudentManagement')));
const Subscribe = loadWithSuspense(lazy(() => import('./pages/Subscribe')));
const RouterSideCar = loadWithSuspense(lazy(() => import('./pages/RouterSideCar')));

const inMobile = isMobile();

const App = () => {
  const { updateUser } = useGlobalUserState();

  React.useEffect(() => {
    const handleLoginSuccess = (authedUser: IAuthUserInfo) => {
      updateUser({
        email: authedUser.email!,
        uid: authedUser.uid || authedUser.id,
      });
    };

    const handleLogoutSuccess = () => {
      updateUser({});
    };

    if (auth.user) {
      handleLoginSuccess(auth.user);
    } else {
      handleLogoutSuccess();
    }

    // 因为 codekidz auth 是同步的，所以这个事件监听偏晚
    eventBus.on('LOGIN_SUCCESS', handleLoginSuccess);
    eventBus.on('LOGOUT_SUCCESS', handleLogoutSuccess);

    return () => {
      eventBus.off('LOGIN_SUCCESS', handleLoginSuccess);
      eventBus.off('LOGOUT_SUCCESS', handleLogoutSuccess);
      // 在 React 生命周期中取消对 firebase auth 的订阅
      auth.dispose();
    };
  }, []);

  return (
    <div className='App'>
      <Routes>
        <Route path='/playground/:course_id' Component={SideBar} />
        <Route path='/playground/:course_id/:lesson_id' Component={SideBar} />
        <Route path='/get-started' Component={SideBar} />
        <Route path='/games/codeclash' Component={SideBar} />
        <Route path='/teacher' Component={SideBar} />
        <Route path='/course' Component={SideBar} />
        <Route path='/game' Component={SideBar} />
        <Route path='/artimind/:book_id?' Component={SideBar} />
        <Route path='/trophy' Component={SideBar} />
        <Route path='/student-management' Component={SideBar} />
      </Routes>
      <Routes>
        <Route path='/' Component={inMobile ? MobileHome : Home} />

        <Route path='/auth' Component={authPlatform === 'supabase' ? AuthSupabase : AuthGoogle} />
        <Route path='/auth-internal' Component={AuthInternal} />
        <Route path='/reset-password' Component={ResetPassword} />

        <Route path='/home' Component={Home} />
        <Route path='/m-home' Component={MobileHome} />
        <Route path='/learn-ai' Component={LearnAI} />
        <Route path='/learn-python' Component={LearnPython} />
        <Route path='/privacy' Component={Privacy} />
        <Route path='/payment-policy' Component={PaymentPolicy} />
        <Route path='/partnership' Component={Partnership} />
        <Route path='/terms-of-use' Component={TermsOfUse} />
        <Route path='/404' Component={PageNotFound} />
        <Route path='/order/success' Component={OrderSuccess} />
        <Route path='/order/canceled' Component={OrderCanceled} />

        <Route path='/book/:share_id' Component={inMobile ? MobileBookPreview : BookPreview} />

        {/* In App starts */}
        <Route path='/playground/:course_id' Component={Course} />
        <Route path='/playground/:course_id/:lesson_id' Component={PlayGround} />
        <Route
          path='/games/codeclash'
          element={<PlayGround alias_course_id='codeclash' alias_lesson_id='codeclash-main' />}
        />
        <Route
          path='/artimind/:book_id?'
          element={<PlayGround alias_course_id='artimind' alias_lesson_id='artimind-main' />}
        />
        <Route
          path='/get-started'
          element={<PlayGround alias_course_id='python' alias_lesson_id='1-1-1' />}
        />
        <Route path='/teacher' Component={Teacher} />
        <Route path='/course' Component={ChooseCourse} />
        <Route path='/game' Component={Game} />
        <Route path='/trophy' Component={Trophy} />
        <Route path='/lesson-intro/:course_id' Component={LessonIntro} />
        <Route path='/student-management' Component={StudentManagement} />
        {/* In App ends */}
        <Route path='/pricing' Component={Subscribe} />
        <Route path='*' Component={PageNotFound} />
      </Routes>
      <Routes>
        <Route path='*' Component={RouterSideCar} />
      </Routes>
    </div>
  );
};

export default App;
