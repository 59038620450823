import { createGlobalState } from 'react-hooks-global-state';

import type { components } from '@/service/openapi';

const initialState: {
  student: components['schemas']['StudentStateOut'] & {
    loading?: boolean;
  };
} = {
  student: {
    loading: false,
  },
};

const { useGlobalState } = createGlobalState(initialState);

export const useGlobalStudentState = () => {
  const [student, updateStudent] = useGlobalState('student');

  return {
    student,
    updateStudent,
  };
};
