import kebabCase from 'lodash/kebabCase';
import { proxy } from 'valtio';

import { type ITheme } from '@/common/interface';

import { darkToken } from './dark-token';
import { lightToken } from './light-token';

interface ThemeConfig {
  value: ITheme;
}

class ThemeManager {
  public theme: ThemeConfig = proxy({
    value: (localStorage.getItem('_theme') || 'light') as ITheme,
  });

  constructor() {
    this.applyCssVars(this.theme.value);
  }

  public change(theme: ITheme) {
    if (!theme) {
      console.log('theme is not defined');
      return;
    }

    this.theme.value = theme;
    localStorage.setItem('_theme', theme);
    this.applyCssVars(theme);
  }

  private applyCssVars(theme: ITheme) {
    document.body.dataset.theme = theme;

    const styleTag = this.getStyleContainer();

    const tokenConfig = theme === 'dark' ? darkToken : lightToken;
    const cssVarsDelc = this.convertToCssVars(tokenConfig);

    styleTag.innerHTML = `:root {${cssVarsDelc}}}`;
  }

  private convertToCssVars(tokenConfig: Record<string, string>): string {
    // textBase --> --text-base
    return Object.keys(tokenConfig)
      .map((key) => `--color-${kebabCase(key)}: ${tokenConfig[key]}`)
      .join(';\n');
  }

  private getStyleContainer(): HTMLStyleElement {
    const styleTag = document.querySelector('style#kidz-theme');
    if (styleTag) return styleTag as HTMLStyleElement;
    const style = document.createElement('style');
    style.id = 'kidz-theme';
    document.head.appendChild(style);
    return style;
  }
}

const themeManager = new ThemeManager();

if (process.env.NODE_ENV !== 'production') {
  // 方便本地调试
  // @ts-ignore
  window._theme = themeManager;
}

export { themeManager };
