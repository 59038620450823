import { Suspense, createElement } from 'react';

export function loadWithSuspense<T = {}>(
  Comp: React.ComponentType<T>,
  fallback?: React.ComponentType,
) {
  return (props: T) => (
    <Suspense fallback={fallback ? createElement(fallback) : null}>
      {/* @ts-ignore */}
      <Comp {...props} />
    </Suspense>
  );
}
