import hexToRgba from 'hex-to-rgba';
import { colorPalette } from './color-palette';

export const lightToken = {
  brandPrimary: colorPalette.brandPrimary,
  textBase: colorPalette.black,
  textContrast: colorPalette.white,
  textDisabled: hexToRgba(colorPalette.originBlack, '0.25'),
  textGrey: colorPalette.grey,
  bgBase: colorPalette.white,
  boxShadow: hexToRgba('#000000', '0.25'),
  border: colorPalette.lightGrey,
  bgInput: colorPalette.white,
  bgPage: colorPalette.lightGrey,
  bgRobotMsg: colorPalette.lightGrey,
  bgUserMsg: colorPalette.lightGreen,
  uploaderBorder: colorPalette.grey,
  bgCard: colorPalette.white,
  // not found
  actionIcon: colorPalette.black,
  actionIconHover: hexToRgba(colorPalette.originBlack, '0.06'),
  otherIcon: hexToRgba(colorPalette.originBlack, '0.25'),
  bannerBg: hexToRgba('#FFFFFF', '0.5'),
};
