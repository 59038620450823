import clsx from 'clsx';

import { Header } from '../Header';

import styles from './styles.module.less';

const LayoutContainer: React.FC<
  React.PropsWithChildren<{
    className?: string;
    header?: React.ReactNode;
  }>
> = (props) => (
  <div className={clsx(styles.pageContainer, props.className)} dir='auto'>
    {props.header !== undefined ? props.header : <Header />}
    {props.children}
  </div>
);

export default LayoutContainer;
