import mitt from 'mitt';

import type { IAuthUserInfo, ICodeClashEvent } from './interface';

interface EventMap {
  LOGIN_SUCCESS: IAuthUserInfo;
  LOGOUT_SUCCESS: undefined;
  NEED_PAID_MODAL: undefined;
  USE_LOGIN_TYPE: 'codekidz' | 'third-party';
  CODECLASH_EVENT: ICodeClashEvent;
}

export const eventBus = mitt<EventMap>();
