import { jwtDecode } from 'jwt-decode';
import { router } from '@/router';
import isPlainObject from 'lodash/isPlainObject';

import { AuthPlatform, type BaseAuth } from './base';
import { eventBus } from '../event-bus';
import type { IUserInfo } from '../interface';

export class CodekidzAuth implements BaseAuth<IUserInfo> {
  static PersistKey = '_ctoken';

  static isAvailable() {
    return !!localStorage.getItem(CodekidzAuth.PersistKey);
  }

  static saveToken(token: string) {
    localStorage.setItem(CodekidzAuth.PersistKey, token);
  }

  private _user: IUserInfo | null = null;

  private _ready: Promise<IUserInfo | null>;

  constructor() {
    this._ready = this.handleAuthStateChanged();
  }

  public async handleAuthStateChanged() {
    const token = await this.retrieveUserToken();

    let user: IUserInfo | null = null;
    if (token) {
      const result = jwtDecode(token);
      // {
      //   iat: 1701440133,
      //   sub: {
      //     uid: '5ff03ae1-9fca-4a74-b958-798ef04288f0',
      //     uname: 'nanjing',
      //     plu: 1701433026
      //   }
      // }
      if (isPlainObject(result) && result?.sub?.uid) {
        user = {
          uid: result.sub.uid,
        };
      }
    }

    this._user = user;

    if (user) {
      eventBus.emit('LOGIN_SUCCESS', user);
    } else {
      eventBus.emit('LOGOUT_SUCCESS');
    }

    return user;
  }

  public authPlatform = AuthPlatform.Codekidz;

  public async retrieveUserToken() {
    const token = localStorage.getItem(CodekidzAuth.PersistKey);
    return token;
  }

  public dispose() {}

  public get ready() {
    return this._ready;
  }

  public get user(): IUserInfo | null {
    return this._user;
  }

  public logout(callbackUrl: string = '/') {
    localStorage.removeItem(CodekidzAuth.PersistKey);
    eventBus.emit('LOGOUT_SUCCESS');

    router.navigate(callbackUrl);
  }
}
