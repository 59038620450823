/**
 * 配置项目|约定项
 */

import defaultTeacherAvatar from '@/assets/teachers/tracy.webp';

// eslint-disable-next-line arrow-body-style
export const getTecherAvatarById = (id: string | undefined, random_emotion: string | undefined) => {
  if (id) {
    try {
      if (random_emotion && random_emotion !== 'default') {
        // eslint-disable-next-line global-require
        return require(`@/assets/teachers/${id.toLowerCase()}_${random_emotion}.webp`);
      }
    } catch (error) {
      console.log('error', error);
    }
    // eslint-disable-next-line global-require
    return require(`@/assets/teachers/${id.toLowerCase()}.webp`);
  }
  return defaultTeacherAvatar;
};

export const languageOptions: Record<string, string> = {
  en: 'English', // English
  fr: 'Français', // French
  de: 'Deutsch', // German
  it: 'Italiano', // Italian
  pl: 'Polski', // Polish
  pt: 'Português', // Portuguese
  es: 'Español', // Spanish
  ru: 'Русский', // Russian
  hi: 'हिन्दी', // Hindi
  ar: 'العربية', // Arabic
  ko: '한국어', // Korean
  ja: '日本語', // Japanese
  'zh-CN': '简体中文', // Simplified Chinese
  'zh-TW': '繁體中文', // Traditional Chinese
};

export const languageDefaultCountry: Record<string, string> = {
  en: 'en-US',
  de: 'de-DE',
  es: 'es-ES',
  fr: 'fr-FR',
  it: 'it-IT',
  ja: 'ja-JP',
  ko: 'ko-KR',
  hi: 'hi-IN',
  ar: 'ar-SA',
  pl: 'pl-PL',
  pt: 'pt-PT',
  ru: 'ru-RU',
  zh: 'zh-CN',
};
