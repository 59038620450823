import { Auth } from '@supabase/auth-ui-react';
import { useTranslation } from 'react-i18next';
import { auth } from '@/service/auth';
import {
  // Import predefined theme
  ThemeSupa,
} from '@supabase/auth-ui-shared';
import { useEffect } from 'react';
import { useGetHandleCourseClick } from '@/service/toapp';
import { Link } from 'react-router-dom';
import { eventBus } from '@/service/event-bus';
import { AuthContainer } from './AuthContainer';

import styles from './styles.module.less';

type Locales = {
  [key: string]: {
    [key: string]: string;
  };
};

const supportedLanguages = ['en', 'fr', 'de', 'it', 'pl', 'es', 'ja', 'zh-CN', 'zh-TW'];
const locales: Locales = {};
for (let i = 0; i < supportedLanguages.length; i++) {
  const lang = supportedLanguages[i];
  locales[lang] = require(`@/i18n/${lang}/supa.json`);
}

export const AuthSupabase = () => {
  const { i18n, t } = useTranslation(['resource']);
  const locale = locales[i18n.language] || locales[i18n.language.split('-')[0]] || locales.en;
  const { supabase } = auth;
  const handleCourseClick = useGetHandleCourseClick();
  useEffect(() => {
    eventBus.emit('USE_LOGIN_TYPE', 'third-party');

    const sub = supabase.auth.onAuthStateChange((event, session) => {
      if (event === 'SIGNED_IN') {
        handleCourseClick();
      }
    });

    return () => {
      sub.data.subscription.unsubscribe();
    };
  }, []);

  return (
    <AuthContainer
      desc={
        <>
          {t('If you want to login using a student account and password, please')}
          &nbsp;
          <Link to='/auth-internal'>{t('switch to the student login entrance.')}</Link>
        </>
      }
    >
      <div className={styles.supaAuthContainer}>
        <Auth
          supabaseClient={auth.supabase}
          appearance={{
            theme: ThemeSupa,
            variables: {
              default: {
                colors: {
                  brand: '#ee6055',
                  brandAccent: '#ff9b85',
                },
              },
            },
          }}
          localization={{
            variables: locale,
          }}
          providers={[]}
        />
      </div>
    </AuthContainer>
  );
};

export default AuthSupabase;
