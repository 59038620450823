import React, { useEffect, useState } from 'react';
import 'firebaseui/dist/firebaseui.css';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { useTranslation } from 'react-i18next';
import { useGetHandleCourseClick } from '@/service/toapp';
import { Link } from 'react-router-dom';
import { eventBus } from '@/service/event-bus';
import { AuthContainer } from './AuthContainer';

import styles from './styles.module.less';

let ui: firebaseui.auth.AuthUI | null = null;

const loadFirebaseUI = (
  language: string,
  setIsLoading: CallableFunction,
  handleCourseClick: CallableFunction,
) => {
  window.firebase = firebase; // Required for firebaseui

  const script = document.createElement('script');

  // Check if language in supported list (en, zh_cn, zh_tw ...)
  language = language.toLowerCase().replace('-', '_');
  if (
    ['fr', 'de', 'it', 'pl', 'pt', 'es', 'ru', 'hi', 'ar', 'ko', 'ja', 'zh_cn', 'zh_tw'].includes(
      language.toLowerCase(),
    )
  ) {
    language = `__${language.toLowerCase()}`;
  } else {
    language = ''; // Default english
  }

  // Download from https://www.gstatic.com/firebasejs/ui/6.1.0/firebase-ui-auth__{LANGUAGE_CODE}.js
  // Supported languages https://github.com/firebase/firebaseui-web/blob/master/LANGUAGES.md
  script.src = `/firebaseui/6.1.0/firebase-ui-auth${language}.js`;

  script.async = false;
  script.crossOrigin = 'anonymous';
  document.head.appendChild(script);

  script.addEventListener('load', () => {
    if (!ui) {
      ui = new window.firebaseui.auth.AuthUI(firebase.auth());
    }
    setIsLoading(false);
    const signInOptions = [
      {
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        requireDisplayName: false,
      },
      {
        provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
      },
      {
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      },
    ];
    if (window.location.host === 'codekidz.ai') {
      // GitHub auth is only available on one domain.
      signInOptions.push({
        provider: firebase.auth.GithubAuthProvider.PROVIDER_ID,
      });
    }
    ui.start('#firebaseui-auth-container', {
      signInOptions,
      signInSuccessUrl: '/',
      privacyPolicyUrl: '/privacy',
      tosUrl: '/terms-of-use',
      callbacks: {
        signInSuccessWithAuthResult: (...args) => {
          // TODO: 这里 auth 可能还没有切换好，不应该在这里登录
          // 这里登录完 token 可能还拿不到，要么自己拿到 token 塞进去 headers 里面
          // 或者监听 LOGIN_SUCCESS 事件
          handleCourseClick();
          return false;
        },
      },
    });
  });

  return script;
};

export const AuthGoogle: React.FC<any> = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { i18n } = useTranslation();
  const handleCourseClick = useGetHandleCourseClick();
  const loaded = React.useRef<boolean>(false);
  const { t } = useTranslation(['resource']);

  useEffect(() => {
    eventBus.emit('USE_LOGIN_TYPE', 'third-party');

    const handleLoginSuccess = () => {
      handleCourseClick();
    };

    eventBus.on('LOGIN_SUCCESS', handleLoginSuccess);
    return () => {
      eventBus.off('LOGIN_SUCCESS', handleLoginSuccess);
    };
  }, []);

  useEffect(() => {
    if (loaded.current) return;
    loaded.current = true;
    const script = loadFirebaseUI(i18n.language, setIsLoading, handleCourseClick);

    return () => {
      // clean up the script when the component in unmounted
      if (script) {
        document.head.removeChild(script);
      }
    };
  }, []);

  return (
    <AuthContainer
      desc={
        <>
          {t('If you want to login using a student account and password, please')}
          &nbsp;
          <Link to='/auth-internal'>{t('switch to the student login entrance.')}</Link>
        </>
      }
    >
      <div className={styles.firebaseAuthContainer} id='firebaseui-auth-container' dir='auto'>
        {isLoading ? <div className={styles.loadingSpinner} dir='auto' /> : null}
      </div>
    </AuthContainer>
  );
};
