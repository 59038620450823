import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { eventBus } from '@/service/event-bus';
import type { components } from '@/service/openapi';

interface Lesson {
  _id: string;
  goal: string;
  title: string;
  unit_id: string;
}

interface Unit {
  _id: string;
  course_id: string;
  title: string;
  children: Lesson[];
}

interface CourseContent {
  units: Unit[];
  lessons: Lesson[];
}

export function transformToUnitsWithChildren(data: CourseContent): Unit[] {
  const unitsWithChildren: Unit[] = data.units.map((unit) => {
    const childrenLessons = data.lessons.filter((lesson) => lesson.unit_id === unit._id);
    return {
      ...unit,
      children: childrenLessons,
    };
  });

  return unitsWithChildren;
}

export async function loadScript(url: string): Promise<void> {
  if (document.querySelector(`[src="${url}"]`)) {
    return Promise.resolve();
  }

  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = url;
    script.onload = (..._: any[]) => {
      resolve();
    };
    script.onerror = reject;
    document.head.appendChild(script);
  });
}

export function sleep(s: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, s);
  });
}

export function isFreeLesson(lesson_id: string) {
  return ['1-1-', '1-2-', 'codeclash-1-', 'artimind-1-1'].some((str) => lesson_id?.startsWith(str));
}

export function lastLessonPath(student: any) {
  // Only used in the situation where the student state is cached in local,
  // if student state is not exist, use useGetHandleCourseClick in homepage.
  if (student.last_lesson_id && student.last_course_id) {
    return `/playground/${student.last_course_id}/${student.last_lesson_id}`;
  }
  return '/get-started';
}

export function getCookie(name: string) {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export function getFestival() {
  // Check which festival is currently active
  // Set 12-01 to 12-31 as Christmas and return 'christmas'
  const date = new Date();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  if (month === 12 && day >= 1 && day <= 31) {
    return 'christmas';
  } if (month === 2 && day >= 1 && day <= 24) {
    return 'spring';
  }
}

export function atDateInterval(start: string, end: string) {
  const now = new Date();
  const startDate = new Date(start);
  const endDate = new Date(end);
  return startDate <= now && now <= endDate;
}

export function matchRatio(s1: string[], s2: string) {
  // Find the number of matching characters from s1 to s2
  let matchCount = 0;
  let indexS2 = 0; // Current index of s2

  s2 = s2.toLowerCase();

  // Loop through s1
  for (let i = 0; i < s1.length; i++) {
    // Find the next matching character in s2
    let tmpIndexS2 = indexS2;
    const lowerS1 = s1[i].toLowerCase();
    // The max distance between the current index and the next matching character should be less than maxDistance,
    // except for the first character
    while (tmpIndexS2 < s2.length) {
      if (lowerS1 === s2.slice(tmpIndexS2, tmpIndexS2 + lowerS1.length)) {
        matchCount++; // Find a match, increase the match count
        indexS2 = tmpIndexS2 + lowerS1.length; // Move to the next character in s2
        break;
      }
      tmpIndexS2++; // 没有找到匹配，检查s2的下一个字符
    }
    // Break if we've reached the end of s2
    if (indexS2 >= s2.length) {
      break;
    }
  }

  // Calculate the matching ratio
  const ratio = matchCount / s1.length;
  return ratio;
}

export function splitStringByWordOrChar(input: string) {
  // Split a string into words or characters
  // Remove spaces, commas, and periods
  const regex =
    /[\u00C0-\u024F\w]+|[\u4E00-\u9FFF]|[\u3040-\u309F\u30A0-\u30FF]|[\uAC00-\uD7AF]|[\u0900-\u097F]|[\uD800-\uDBFF][\uDC00-\uDFFF]|./g;

  const matches = input.match(regex);
  return matches ? matches.filter((match) => !match.match(/[\s,!.]/)) : [];
}

export const firebaseImgUrlPrefix =
  'https://firebasestorage.googleapis.com/v0/b/codekidz.appspot.com/o/';

export function getImagePath(imageUrl: string) {
  const parsedUrl = new URL(imageUrl);
  let pathname = decodeURIComponent(parsedUrl.pathname);
  // Remove bucket name from pathname, seperated by /o/
  // eslint-disable-next-line prefer-destructuring
  const parts = pathname.split(/appspot\.com(\/o)?\//);
  pathname = parts[parts.length - 1];
  return pathname;
}

export async function getFSImageUrl(imageUrl: string, thumbnailSize?: string): Promise<string> {
  // Get image url from firebase storage
  // Check if the thumbnail exists, if not, return original image
  // Thumbnail path is the original path without the extension, plus _200x200.webp
  // A valid url example: https://firebasestorage.googleapis.com/v0/b/codekidz.appspot.com/o/upload%2Frewards%2Fcards%2F00001-2497396157.webp?alt=media&token=0b4f41af-5392-4629-b4da-23eab18dc917
  // Or without token: https://storage.googleapis.com/codekidz.appspot.com/upload/rewards/cards/00118-682963680.webp
  let pathname = imageUrl;
  let isValidUrl = false;
  try {
    const parsedUrl = new URL(imageUrl);
    pathname = decodeURIComponent(parsedUrl.pathname);
    // Remove bucket name from pathname, seperated by /o/
    // eslint-disable-next-line prefer-destructuring
    const parts = pathname.split(/appspot\.com(\/o)?\//);
    pathname = parts[parts.length - 1];
    isValidUrl = true;
  } catch {
    // Ignore
  }
  // Find the last dot in the pathname
  const lastDotIndex = pathname.lastIndexOf('.');
  // Extract the extension from the pathname
  const extName = lastDotIndex !== -1 ? pathname.substring(lastDotIndex) : '';

  if (isValidUrl && extName === '.webp') {
    // Don't get thumbnail for complete webp url
    return imageUrl;
  }

  if (extName === '.webp') {
    // Get complete download url for webp image
    try {
      const downloadUrl = await getDownloadURL(ref(getStorage(), imageUrl));
      return downloadUrl;
    } catch (error) {
      // Original file doesn't exist
      return '';
    }
  }
  let thumbnailPath = `${pathname.split('.').slice(0, -1).join('.')}_${thumbnailSize}.webp`;
  // Add thumbnails before the filename.
  thumbnailPath = `${thumbnailPath.split('/').slice(0, -1).join('/')}/thumbnails/${thumbnailPath
    .split('/')
    .slice(-1)}`;
  try {
    const thumbnailUrl = await getDownloadURL(ref(getStorage(), thumbnailPath));
    return thumbnailUrl;
  } catch (error) {
    if (isValidUrl) {
      // If the url is valid, return the original url
      return imageUrl;
    }
    // Thumbnail doesn't exist, use original image
    try {
      const downloadUrl = await getDownloadURL(ref(getStorage(), pathname));
      return downloadUrl;
      // eslint-disable-next-line @typescript-eslint/no-shadow
    } catch (error) {
      // Original file doesn't exist
      return '';
    }
  }
}

export async function updateQuota(remainQuota: string | null, updateStudent: (r: any) => void) {
  if (!remainQuota) {
    return;
  }
  const remainQuotaParts = remainQuota.split(',');
  if (remainQuotaParts.length > 0) {
    // query,...
    const updates: Record<string, number> = {};
    const remainQueries = parseInt(remainQuotaParts[0], 10);
    let needPay = false;
    if (!isNaN(remainQueries)) {
      updates.remain_queries = remainQueries;
      // update
      if (remainQueries <= 0) {
        needPay = true;
      }
    }
    const remainAI2Img = parseInt(remainQuotaParts[1], 10);
    if (!isNaN(remainAI2Img)) {
      updates.remain_ai2img = remainAI2Img;
    }
    if (Object.keys(updates).length > 0) {
      // Update remain queries if it's a number
      updateStudent((r) => ({
        ...r,
        ...updates,
      }));
    }
    if (needPay) {
      // Now we only popup the modal when the remain queries is 0
      eventBus.emit('NEED_PAID_MODAL');
    }
  }
}

export function getBookPreviewUrl(book: components['schemas']['Book']) {
  const title = book.title.replace(/\s/g, '-').slice(0, 20);
  const mixedPath = `${title}-${book.unique_share_id}`;
  return `${window.location.origin}/book/${encodeURIComponent(mixedPath)}`;
}

export function parseBookSharePath(mixedShareId: string) {
  const parts = decodeURIComponent(mixedShareId).split('-');
  const uniqueShareId = parts.pop();
  const title = parts.join('-');
  return { title, uniqueShareId };
}

export function getSmoothTextSetter(
  setText: (text: string) => void,
  callback?: (finalText: string) => void,
  totalDuration: number = 1000,
) {
  let timeoutId: NodeJS.Timeout;
  let currentIndex = 0;
  // Set text with a smooth animation
  const smoothSetText = (resText: string, done: boolean = false) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const restLength = resText.length - currentIndex;
    const interval = totalDuration / restLength; // Dynamic interval based on string length

    // Test if restText is markdown image, render it directly, no need to wait
    if (resText[currentIndex] === '!') {
      const restText = resText.substring(currentIndex);
      // Use regex to match the image part
      const match = restText.match(/!\[.*\]\(.*\)/);
      if (match) {
        setText((prevText) => prevText + match[0]);
        currentIndex += match[0].length;
        smoothSetText(resText);
        return;
      }
    }

    setText((prevText) => {
      let finalText = prevText;
      if (currentIndex >= resText.length) {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
      } else {
        if (done) {
          finalText += resText.substring(currentIndex);
          currentIndex += resText.substring(currentIndex).length;
        } else {
          finalText += resText[currentIndex];
          currentIndex++;
        }
        if (!done) {
          timeoutId = setTimeout(() => {
            smoothSetText(resText);
          }, interval);
        }
      }
      if (callback) {
        callback(finalText);
      }
      return finalText;
    });
  };

  return smoothSetText;
}
