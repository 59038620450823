/**
 * 跟 figma 设计稿色板对应
 */
export const colorPalette = {
  green: '#60D394',
  lightGreen: '#AAF683',
  yellow: '#FFD97D',
  salmon: '#FF9B85',
  grey: '#8D99AE',
  lightGrey: '#EDF2F4',
  blue: '#B1D2FE',
  black: '#55504F',
  blackBg: '#595C71',
  darkText: '#EBEBEB',
  darkBlack: '#0B1D35',
  brandPrimary: '#EE6055',
  white: '#fff',
  // 色板没有的颜色
  originBlack: '#000',
};
