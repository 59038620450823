import { requestClient } from '@/service/request';
import type { components } from '@/service/openapi';
import { getFSImageUrl } from '@/utils/helper';

export async function fetchStudentState() {
  const studentState: components['schemas']['StudentStateOut'] = (
    await requestClient.get('/student')
  ).data;
  if (window.clarity && studentState && studentState._id) {
    window.clarity('identify', studentState._id);
    if (studentState.email?.length > 0) {
      window.clarity('set', 'email', studentState.email);
    }
  }
  if (studentState?.avatar_url) {
    const thumbnailUrl = await getFSImageUrl(studentState.avatar_url, '200x200');
    if (thumbnailUrl) {
      studentState.avatar_url = thumbnailUrl;
    }
  }
  return studentState;
}
