import './i18n';
import React from 'react';
import * as ReactDOM from 'react-dom/client';
import { ConfigProvider, App, theme } from 'antd';
import { RouterProvider } from 'react-router-dom';
import { HappyProvider } from '@ant-design/happy-work-theme';
import * as Sentry from '@sentry/react';
import type { Metric } from 'web-vitals';
import { t } from 'i18next';
import { useSnapshot } from 'valtio';

import 'antd/dist/reset.css';

import reportWebVitals from './reportWebVitals';
import { router } from './router';
import { NoticeHolder } from './components/NoticeHolder';
import { antdDarkTheme, antdLightTheme } from './antd-config';
import { loadWithSuspense } from './utils/suspense-wrapper';

import { themeManager } from './service/theme-manager';

import './index.less';

const GDPRPopup = loadWithSuspense(React.lazy(() => import('./components/GDPRPopup')));
const ErrorFallback = loadWithSuspense(
  React.lazy(() => import('./components/ErrorFallback')),
  () => <p dir='auto'>{t('Something went wrong')}</p>,
);

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    // 将分支名作为环境变量注入作为 release 前缀
    // 保持跟 ci 上传 sourcemap 到 sentry 保持一致
    release: `${process.env.GIT_BRANCH}-${process.env.GIT_REF}`,
    dsn: 'https://f14bb6ed507f3a60cd47ca2be65fbc16@o425351.ingest.sentry.io/4506125708623872',
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          // /^http:\/\/localhost:/,
          /^https:\/\/codekidz\.(xyz|ai)\//,
          /^codekidz-.+\.web\.app$/,
        ],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const AppWrapper = () => {
  const { value: currentTheme } = useSnapshot(themeManager.theme);
  // eslint-disable-next-line arrow-body-style
  const themeConfig = React.useMemo(() => {
    const isDark = currentTheme === 'dark';
    return {
      cssVar: {},
      algorithm: isDark ? theme.darkAlgorithm : theme.defaultAlgorithm,
      ...(isDark ? antdDarkTheme : antdLightTheme),
    };
  }, [currentTheme]);

  return (
    <HappyProvider>
      <ConfigProvider theme={themeConfig}>
        <App>
          <RouterProvider router={router} />
          <NoticeHolder />
        </App>
        <GDPRPopup />
      </ConfigProvider>
    </HappyProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  // https://docs.sentry.io/platforms/javascript/guides/react/features/error-boundary
  <Sentry.ErrorBoundary
    showDialog
    fallback={({ error, componentStack, resetError }) => (
      <ErrorFallback error={error} componentStack={componentStack} onReset={resetError} />
    )}
  >
    <AppWrapper />
  </Sentry.ErrorBoundary>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals((metric: Metric) => {
  const { name, delta, value, id } = metric;
  // Assumes the global `gtag()` function exists, see:
  // https://developers.google.com/analytics/devguides/collection/ga4
  // https://www.npmjs.com/package/web-vitals#send-the-results-to-google-analytics
  // @ts-ignore
  window.gtag?.('event', name, {
    // Built-in params:
    value: delta, // Use `delta` so the value can be summed.
    // Custom params:
    metric_id: id, // Needed to aggregate events.
    metric_value: value, // Optional.
    metric_delta: delta, // Optional.

    // OPTIONAL: any additional params or debug info here.
    // See: https://web.dev/debug-performance-in-the-field/
    // metric_rating: 'good' | 'needs-improvement' | 'poor',
    // debug_info: '...',
    // ...
  });
});
