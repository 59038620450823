import React, { useEffect } from 'react';
import { Button, Flex, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { auth, requestClient } from '@/service/request';
import { CodekidzAuth } from '@/service/auth/codekidz-auth';
import { router } from '@/router';
import { eventBus } from '@/service/event-bus';
import { AuthContainer } from './AuthContainer';

import styles from './styles.module.less';

type FieldType = {
  username?: string;
  password?: string;
};

export const AuthInternal: React.FC<any> = () => {
  const { t } = useTranslation(['resource']);
  const [form] = Form.useForm();

  const onLoginClick = () => {
    const username = form.getFieldValue('username');
    const password = form.getFieldValue('password');
    handleLogin(username, password);
  };

  const handleLogin = (username: string, password: string) => {
    requestClient
      .put('/student/auth', {
        body: {
          unique_name: username,
          password,
        },
      })
      .then((res) => {
        // @ts-ignore
        if (res.data.access_token) {
          // TODO: 优化实现
          // @ts-ignore
          CodekidzAuth.saveToken(res.data.access_token);
          // @ts-ignore
          auth.handleAuthStateChanged?.();
          router.navigate('/');
        }
      });
  };

  useEffect(() => {
    eventBus.emit('USE_LOGIN_TYPE', 'codekidz');
  }, []);

  return (
    <AuthContainer
      desc={
        <>
          {t('If you are an individual, a parent, or a teacher, please')}
          &nbsp;
          <Link to='/auth'>{t('switch to the primary sign-in entrance.')}</Link>
        </>
      }
    >
      <div className={styles.loginForm}>
        <Form name='basic' layout='vertical' autoComplete='off' form={form} onFinish={onLoginClick}>
          <Form.Item<FieldType>
            label='Username'
            name='username'
            rules={[
              {
                required: true,
                pattern: /^[A-Za-z0-9_]+$/,
                message: t('Please input your username!'),
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item<FieldType>
            label='Password'
            name='password'
            rules={[
              {
                required: true,
                pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*()_+,\-./:;<=>?[\]^`{|}~]{8,}$/,
                message: t('Please input your password!'),
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Flex justify='end'>
              <Button type='default' htmlType='submit'>
                {t('LOGIN')}
              </Button>
            </Flex>
          </Form.Item>
        </Form>
      </div>
    </AuthContainer>
  );
};
