import { useGlobalStudentState } from '@/states/global-student';
import { useNavigate } from 'react-router-dom';
import { fetchStudentState } from '@/service/student';

const useGetHandleCourseClick = () => {
  const { updateStudent } = useGlobalStudentState();
  const navigate = useNavigate();
  // 去学习课程
  const handleCourseClick = (courseId: string | null = null) => {
    fetchStudentState().then((studentState) => {
      updateStudent(studentState);
      if (studentState.preferred_teacher) {
        if (!courseId && studentState.last_lesson_id && studentState.last_course_id) {
          navigate(`/playground/${studentState.last_course_id}/${studentState.last_lesson_id}`);
        } else if (courseId) {
          navigate(`/playground/${courseId}`);
        } else {
          navigate(`/playground/1`);
        }
      } else {
        navigate('/teacher');
      }
    });
  };
  return handleCourseClick;
};

export { useGetHandleCourseClick };
