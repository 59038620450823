import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { languageOptions } from './utils/config';

const debug = false;
i18n
  // 检测用户当前使用的语言
  // 文档: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // 注入 react-i18next 实例
  .use(initReactI18next)
  // 初始化 i18next
  // 配置参数的文档: https://www.i18next.com/overview/configuration-options
  .init({
    debug,
    fallbackLng: (code) => {
      // Stripe the country code if the lang not exist in the supportedLngs
      if (languageOptions[code]) {
        return code;
      }
      if (languageOptions[code.split('-')[0]]) {
        return code.split('-')[0];
      }
      return 'en';
    },
    nonExplicitSupportedLngs: true,
    interpolation: {
      escapeValue: false,
    },
    initImmediate: false,
    // lng: 'en', // 默认加载的语言，本项目不需要此项
    ns: ['resource'],
    resources: {},
  });

// 在需要切换语言时调用此函数来动态加载对应语言的资源文件
export async function loadLanguage(lang: string) {
  if (!languageOptions[lang] && !languageOptions[lang.split('-')[0]]) {
    lang = 'en';
  } else if (languageOptions[lang.split('-')[0]]) {
    [lang] = lang.split('-');
  }
  if (!i18n.hasResourceBundle(lang, 'resource')) {
    const translation = await import(`./i18n/${lang}/resource.json`);
    i18n.addResourceBundle(lang, 'resource', translation.default, true, true);
  }
  await i18n.changeLanguage(lang);
}

// 获取嗅探到的语言文件
loadLanguage(i18n.language);

document.documentElement.lang = i18n.language;
i18n.on('languageChanged', (lng) => {
  document.documentElement.lang = lng;
});

export default i18n;
