import type {
  ErrorResponse,
  HttpMethod,
  SuccessResponse,
  FilterKeys,
  MediaType,
  PathsWithMethod,
  ResponseObjectMap,
  OperationRequestBodyContent,
} from 'openapi-typescript-helpers';
import type { AxiosRequestConfig } from 'axios';

import i18n from '@/i18n';

import { axiosClient } from './axios-client';
import type { paths } from './openapi';
import { auth } from './auth';

// Note: though "any" is considered bad practice in general, this library relies
// on "any" for type inference only it can give.  Same goes for the "{}" type.
/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/ban-types */

type QuerySerializer<T> = (
  query: T extends { parameters: any }
    ? NonNullable<T['parameters']['query']>
    : Record<string, unknown>,
) => string;

/** serialize query params to string */
function defaultQuerySerializer<T = unknown>(q: T): string {
  const search = new URLSearchParams();
  if (q && typeof q === 'object') {
    // eslint-disable-next-line no-restricted-syntax
    for (const [k, v] of Object.entries(q)) {
      if (v === undefined || v === null) continue;
      search.set(k, v);
    }
  }
  return search.toString();
}

/** Construct URL string from baseUrl and handle path and query params */
function createFinalURL<O>(
  url: string,
  options: {
    params: { query?: Record<string, unknown>; path?: Record<string, unknown> };
    querySerializer: QuerySerializer<O>;
  },
): string {
  let finalURL = url as string;
  if (options.params.path) {
    // eslint-disable-next-line no-restricted-syntax
    for (const [k, v] of Object.entries(options.params.path))
      finalURL = finalURL.replace(`{${k}}`, encodeURIComponent(String(v)));
  }
  if (options.params.query) {
    const search = options.querySerializer(options.params.query as any);
    if (search) finalURL += `?${search}`;
  }
  return finalURL;
}

interface DefaultParamsOption {
  params?: { query?: Record<string, unknown> };
}
type ParamsOption<T> = T extends { parameters: any }
  ? { params: NonNullable<T['parameters']> }
  : DefaultParamsOption;
type RequestBodyOption<T> = OperationRequestBodyContent<T> extends never
  ? { body?: never }
  : undefined extends OperationRequestBodyContent<T>
  ? { body?: OperationRequestBodyContent<T> }
  : { body: OperationRequestBodyContent<T> };

type FetchOptions<T> = RequestOptions<T> &
  Omit<RequestInit, 'body'> & {
    /**
     * 是否忽略全局报错处理
     */
    ignoreGlobalErrorHandler?: boolean;
  } & Partial<AxiosRequestConfig>;

type FetchResponse<T> =
  | {
      data: FilterKeys<SuccessResponse<ResponseObjectMap<T>>, MediaType>;
      error?: never;
      response: Response;
    }
  | {
      data?: never;
      error: FilterKeys<ErrorResponse<ResponseObjectMap<T>>, MediaType>;
      response: Response;
    };
type RequestOptions<T> = ParamsOption<T> & RequestBodyOption<T>;

function createClient<Paths extends {}>(
  clientOptions: {
    /** global querySerializer */
    querySerializer?: QuerySerializer<unknown>;
  } = {},
) {
  async function coreFetch<P extends keyof Paths, M extends HttpMethod>(
    url: P,
    fetchOptions: FetchOptions<M extends keyof Paths[P] ? Paths[P][M] : never>,
  ): Promise<FetchResponse<M extends keyof Paths[P] ? Paths[P][M] : unknown>> {
    const { querySerializer = defaultQuerySerializer } = clientOptions;
    const { body: data, params = {}, ...init } = fetchOptions || {};

    const finalURL = createFinalURL(url as string, { params, querySerializer });
    return (await axiosClient(finalURL, {
      data,
      ...init,
    } as any)) as any;
  }

  return {
    /** Call a GET endpoint */
    async get<P extends PathsWithMethod<Paths, 'get'>>(
      url: P,
      init?: FetchOptions<FilterKeys<Paths[P], 'get'>>,
    ) {
      return coreFetch<P, 'get'>(url, { ...init, method: 'GET' } as any);
    },
    /** Call a PUT endpoint */
    async put<P extends PathsWithMethod<Paths, 'put'>>(
      url: P,
      init?: FetchOptions<FilterKeys<Paths[P], 'put'>>,
    ) {
      return coreFetch<P, 'put'>(url, { ...init, method: 'PUT' } as any);
    },
    /** Call a POST endpoint */
    async post<P extends PathsWithMethod<Paths, 'post'>>(
      url: P,
      init?: FetchOptions<FilterKeys<Paths[P], 'post'>>,
    ) {
      return coreFetch<P, 'post'>(url, { ...init, method: 'POST' } as any);
    },
    /** Call a DELETE endpoint */
    async delete<P extends PathsWithMethod<Paths, 'delete'>>(
      url: P,
      init?: FetchOptions<FilterKeys<Paths[P], 'delete'>>,
    ) {
      return coreFetch<P, 'delete'>(url, { ...init, method: 'DELETE' } as any);
    },
    /** Call a OPTIONS endpoint */
    async options<P extends PathsWithMethod<Paths, 'options'>>(
      url: P,
      init?: FetchOptions<FilterKeys<Paths[P], 'options'>>,
    ) {
      return coreFetch<P, 'options'>(url, { ...init, method: 'OPTIONS' } as any);
    },
    /** Call a HEAD endpoint */
    async head<P extends PathsWithMethod<Paths, 'head'>>(
      url: P,
      init?: FetchOptions<FilterKeys<Paths[P], 'head'>>,
    ) {
      return coreFetch<P, 'head'>(url, { ...init, method: 'HEAD' } as any);
    },
    /** Call a PATCH endpoint */
    async patch<P extends PathsWithMethod<Paths, 'patch'>>(
      url: P,
      init?: FetchOptions<FilterKeys<Paths[P], 'patch'>>,
    ) {
      return coreFetch<P, 'patch'>(url, { ...init, method: 'PATCH' } as any);
    },
    /** Call a TRACE endpoint */
    async trace<P extends PathsWithMethod<Paths, 'trace'>>(
      url: P,
      init?: FetchOptions<FilterKeys<Paths[P], 'trace'>>,
    ) {
      return coreFetch<P, 'trace'>(url, { ...init, method: 'TRACE' } as any);
    },
  };
}

export const requestClient = createClient<paths>();

export const request = async (input: RequestInfo | URL, init?: RequestInit) => {
  await auth.ready;

  // 每次从 auth 中获取 token
  const idToken = await auth.retrieveUserToken();

  return fetch(input, {
    ...init,
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': i18n.language,
      ...init?.headers,
      ...(idToken ? { Authorization: `Bearer ${idToken}` } : {}),
      ...(auth.authPlatform ? { 'X-Auth-Platform': auth.authPlatform } : {}),
    },
    mode: 'cors',
  });
};

export { auth };
