const validAuthDomainsRegex = /^(codekidz\.ai|codekidz\.xyz|codekidz.*web\.app)$/;
// eslint-disable-next-line import/no-mutable-exports
let authDomain = window.location.host;
// Use regex to validate auth domain
if (!validAuthDomainsRegex.test(authDomain)) {
  authDomain = 'codekidz.ai';
}

const authPlatform =
  authDomain === 'codekidz.xyz' || authDomain.includes('feat-supabase') ? 'supabase' : 'firebase';

export { authDomain, authPlatform };
