import type { ThemeConfig } from 'antd';
import merge from 'lodash/merge';
import hexToRgba from 'hex-to-rgba';

import { colorPalette } from '@/service/theme-manager/color-palette';

/**
 * 非颜色相关的 antd 样式覆盖
 */
const antdBasicTheme: ThemeConfig = {
  token: {
    fontFamily: `Bubblegum Sans, Arial, Helvetica Neue, Helvetica, sans-serif`,
    // fontSize: 18,
    lineWidth: 2,
    borderRadius: 20,
  },
  components: {
    Button: {
      lineWidth: 2,
      controlHeightLG: 58,
      contentFontSizeLG: 28,
      paddingBlockLG: 0,
      // fontSizeLG: 28,
      // controlHeightLG: 56,
    },
  },
};

/**
 * 颜色相关的 antd 样式覆盖
 * light theme
 */
export const antdLightTheme: ThemeConfig = merge({}, antdBasicTheme, {
  token: {
    colorPrimary: colorPalette.brandPrimary,
    // colorText: colorPalette.brandPrimary,
    colorBorder: colorPalette.brandPrimary,
  },
  components: {
    Button: {
      colorBorder: colorPalette.brandPrimary,
      colorText: colorPalette.brandPrimary,
      colorBgBase: colorPalette.white,
      colorLinkHover: colorPalette.salmon,
      colorLinkActive: colorPalette.brandPrimary,
      // colorBgTextHover: colorPalette.brandPrimary,
      // textHoverBg: colorPalette.brandPrimary,
    },
    Input: {
      activeBg: colorPalette.white,
    },
  },
});

/**
 * 颜色相关的 antd 样式覆盖
 * dark theme
 */
export const antdDarkTheme: ThemeConfig = merge({}, antdBasicTheme, {
  token: {
    colorPrimary: colorPalette.brandPrimary,
    colorTextPlaceholder: colorPalette.white,
    colorBorder: colorPalette.brandPrimary,
    colorBgContainer: colorPalette.blackBg,
    boxShadow: hexToRgba(colorPalette.grey, '0.25'),
    // 弹层颜色
    // colorEgElevated:
  },
  components: {
    Button: {
      colorBorder: colorPalette.brandPrimary,
      colorText: colorPalette.brandPrimary,
      colorBgBase: colorPalette.white,
      colorLinkHover: colorPalette.salmon,
      colorLinkActive: colorPalette.brandPrimary,
      defaultBg: 'transparent',
      // colorBgTextHover: colorPalette.brandPrimary,
      // textHoverBg: colorPalette.brandPrimary,
    },
    Input: {
      activeBg: colorPalette.blackBg,
      activeShadow: colorPalette.blackBg,
      hoverBg: colorPalette.blackBg,
    },
    Modal: {
      contentBg: colorPalette.darkBlack,
    },
    Tree: {
      nodeHoverBg: hexToRgba(colorPalette.originBlack, 0.25),
      nodeSelectedBg: hexToRgba(colorPalette.originBlack, 0.5),
    },
  },
});
