import axios from 'axios';

import { message } from '@/components/NoticeHolder';
import i18n from '@/i18n';
import { t } from 'i18next';
import { eventBus } from '@/service/event-bus';
import { auth } from './auth';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(async (request) => {
  // TODO: 支持超时
  await auth.ready;
  // 每次从 auth 中获取 token
  const idToken = await auth.retrieveUserToken();
  if (idToken) {
    if (auth.authPlatform) {
      request.headers.set('X-Auth-Platform', auth.authPlatform);
    }
    request.headers.setAuthorization(`Bearer ${idToken}`);
  }
  request.headers['Accept-Language'] = i18n.language;

  return request;
});

// 添加响应拦截器
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // 对响应错误进行处理
    const statusCode = error.response?.status || null;
    const data = error.response?.data || null;

    // 处理 401 错误
    if (statusCode === 401) {
      message.error(t('Your login has expired, please log in again.'));
      auth.logout();
      return;
    }

    // 处理其他错误
    let errorResponse: string;

    if (typeof data?.detail === 'string') {
      errorResponse = data?.detail;
    } else if (typeof data?.detail?.message === 'string') {
      errorResponse = data?.detail?.message;
    } else if (error.request) {
      errorResponse = error.request.message || error.request.statusText;
    } else {
      errorResponse = error.message;
    }

    message.error(errorResponse || t('Unknown error'));
    // 处理 402 错误，即余额不足
    if (statusCode === 402) {
      eventBus.emit('NEED_PAID_MODAL');
    }
    return Promise.reject(error);
  },
);

export const axiosClient = axiosInstance;
