import type { IAuthUserInfo } from '../interface';

export enum AuthPlatform {
  Firebase = 'firebase',
  Supabase = 'supabase',
  Codekidz = 'codekidz',
}
/**
 * Base class for authentication.
 * @template TUser The type of the user object.
 */
export abstract class BaseAuth<TUser extends IAuthUserInfo> {
  /**
   * The authentication platform
   */
  public abstract authPlatform: AuthPlatform;

  /**
   * A promise that resolves when the authentication is ready.
   */
  public abstract get ready(): Promise<any>;

  /**
   * The current user object.
   */
  public abstract get user(): TUser | null;

  /**
   * Retrieves the user token.
   * @returns A promise that resolves with the user token.
   */
  public abstract retrieveUserToken(): Promise<string | null>;

  /**
   * Logs out the user.
   */
  public abstract logout(): void;

  /**
   * Disposes the authentication resources.
   */
  public abstract dispose(): void;

  // protected async notifyAuthStatus(user: TUser | null) {
  //   if (user) {
  //     eventBus.emit('LOGIN_SUCCESS', user);
  //   } else {
  //     eventBus.emit('LOGOUT_SUCCESS');
  //   }
  // }
}
