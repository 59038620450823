import { router } from '@/router';
import { createClient } from '@supabase/supabase-js';
import type { SupabaseClient, User as SupaUser } from '@supabase/supabase-js';

import { AuthPlatform, type BaseAuth } from './base';
import { eventBus } from '../event-bus';

export class SupabaseAuth implements BaseAuth<SupaUser> {
  private supabase: SupabaseClient;

  public authPlatform = AuthPlatform.Supabase;

  private _user: SupaUser | null = null;

  private _ready: Promise<SupaUser | null>;

  constructor() {
    this.supabase = createClient(
      'https://fsafiunloovadptedhvl.supabase.co',
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImZzYWZpdW5sb292YWRwdGVkaHZsIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTczMzkzODMsImV4cCI6MjAxMjkxNTM4M30.R9dytLeG0XqIU7j2CWxF3nDjFM4DKM_6trrUYoICGL0',
    );
  }

  private async handleAuthStateChanged(user: SupaUser | null) {
    this._user = user;
    await this.retrieveUserToken();
    if (user) {
      eventBus.emit('LOGIN_SUCCESS', user);
    } else {
      eventBus.emit('LOGOUT_SUCCESS');
    }
  }

  public async retrieveUserToken() {
    const token = (await this.supabase.auth.getSession()).data?.session?.access_token;
    return token || null;
  }

  private disposables: Array<() => void> = [];

  public dispose() {
    this.disposables.forEach((disposable) => {
      disposable?.();
    });
  }

  public get ready() {
    if (!this._ready) {
      this._ready = new Promise((resolve) => {
        const listener = this.supabase.auth.onAuthStateChange((event, session) => {
          const user = session?.user;
          this.handleAuthStateChanged(user || null);
          resolve(user || null);
        });
        this.disposables.push(listener.data.subscription.unsubscribe);
      });
    }
    return this._ready;
  }

  public get user(): SupaUser | null {
    return this._user;
  }

  public logout(callbackUrl: string = '/') {
    this.supabase.auth.signOut();
    eventBus.emit('LOGOUT_SUCCESS');

    router.navigate(callbackUrl);
  }
}
