import { createGlobalState } from 'react-hooks-global-state';

import type { IUserInfo } from '@/service/interface';

const initialState: {
  user: IUserInfo;
} = {
  user: {},
};

const { useGlobalState } = createGlobalState(initialState);

export const useGlobalUserState = () => {
  const [user, updateUser] = useGlobalState('user');

  return {
    user,
    updateUser,
  };
};
