import { useNavigate } from 'react-router-dom';

import { isSmallScreen } from '@/utils/is-mobile';
import styles from './styles.module.less';
import logoIcon from '../../assets/icons/logo.webp';
import christLogoIcon from '../../assets/festival/christmas-logo.webp';
import springLogoIcon from '../../assets/festival/spring-logo.webp';
import { getFestival } from '../../utils/helper';

const FEST_LOGOS = {
  christmas: christLogoIcon,
  spring: springLogoIcon,
};

const festival = getFestival();

const Logo = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.logoWrapper} onClick={() => navigate('/')}>
      <img
        className={styles.codeKidLogo}
        width={isSmallScreen() ? 32 : 45}
        height={isSmallScreen() ? 32 : 45}
        src={festival ? FEST_LOGOS[festival] || logoIcon : logoIcon}
        alt='logo'
      />
      <span className={styles.logoName} dir='auto'>
        CodeKidz
      </span>
    </div>
  );
};

export default Logo;
