import hexToRgba from 'hex-to-rgba';
import { colorPalette } from './color-palette';

export const darkToken = {
  brandPrimary: colorPalette.brandPrimary,
  textBase: colorPalette.darkText,
  textContrast: colorPalette.lightGrey,
  textDisabled: hexToRgba(colorPalette.white, '0.25'),
  textGrey: colorPalette.grey,
  bgBase: colorPalette.darkBlack,
  bgPage: colorPalette.blackBg,
  boxShadow: hexToRgba('#000000', '0.25'),
  // cannot found
  border: colorPalette.grey,
  uploaderBorder: colorPalette.grey,
  bgInput: colorPalette.blackBg,
  bgRobotMsg: colorPalette.darkBlack,
  bgUserMsg: colorPalette.blackBg,
  borderRobotMsg: colorPalette.blackBg,
  bgCard: colorPalette.darkBlack,
  actionIcon: colorPalette.darkText,
  actionIconHover: hexToRgba(colorPalette.white, '0.12'),
  otherIcon: colorPalette.darkText,
  bannerBg: hexToRgba('#000000', '0.5'),
};
