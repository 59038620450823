import { Link } from 'react-router-dom';
import clsx from 'clsx';

import Logo from '../Logo';

import styles from './styles.module.less';

export const Header: React.FC<
  React.PropsWithChildren<{
    className?: string;
  }>
> = (props) => (
  <div className={clsx(styles.header, props.className)}>
    <Link to='/'>
      <Logo />
    </Link>
    {props.children}
  </div>
);
