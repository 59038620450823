import firebase from 'firebase/compat/app';
import { router } from '@/router';
import type { BaseAuth } from './base';
import { AuthPlatform } from './base';
import { eventBus } from '../event-bus';

export class FirebaseAuth implements BaseAuth<firebase.User> {
  private _user: firebase.User | null = null;

  private _ready: Promise<firebase.User | null>;

  private disposables: Array<() => void> = [];

  private _firebaseAuth: firebase.auth.Auth;

  constructor() {
    this._firebaseAuth = firebase.auth();

    this._ready = new Promise((resolve) => {
      this.disposables.push(
        this._firebaseAuth.onAuthStateChanged(async (user) => {
          await this.handleAuthStateChanged(user);
          resolve(user);
        }),
      );
    });
  }

  public authPlatform = AuthPlatform.Firebase;

  private async handleAuthStateChanged(user: firebase.User | null) {
    this._user = user;
    await this.retrieveUserToken();

    if (user) {
      eventBus.emit('LOGIN_SUCCESS', user);
    } else {
      eventBus.emit('LOGOUT_SUCCESS');
    }
  }

  public async retrieveUserToken() {
    if (!this._user) return null;
    const token = await this._user.getIdToken();
    return process.env.NODE_ENV !== 'production' ? 'test' : token;
  }

  public dispose() {
    this.disposables.forEach((disposable) => {
      disposable?.();
    });
  }

  public get ready() {
    return this._ready;
  }

  public get user(): firebase.User | null {
    return this._user;
  }

  public logout(callbackUrl: string = '/') {
    this._firebaseAuth.signOut();
    eventBus.emit('LOGOUT_SUCCESS');
    router.navigate(callbackUrl);
  }
}
